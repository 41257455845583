export default ({ data }) => {
  return {
    name: data.name,
    title: data.title,
    text: data.text,
    items: data.items?.map((item) => ({
      id: item.id,
      title: item.title,
      text: item.text,
    })),
  };
};
